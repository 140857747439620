import {
  ref,
  Ref,
} from '@nuxtjs/composition-api';

export interface TimelineState {
  overlayType: Ref<1 | null>
  telemedicineId: Ref<string>
  projectId: Ref<string>
}

export const useTimelineState = (): TimelineState => {
  const overlayType = ref<1 | null>(null);
  const telemedicineId = ref<string>('');
  const projectId = ref<string>('');
  return {
    overlayType,
    telemedicineId,
    projectId,
  };
};
