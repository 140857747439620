import Vue from 'vue'
import L from 'leaflet'

// Leafletのデフォルトアイコンの設定を修正
delete (L.Icon.Default.prototype as any)._getIconUrl
/*
L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/leaflet/marker-icon-2x.png',
  iconUrl: '/leaflet/marker-icon.png',
  shadowUrl: '/leaflet/marker-shadow.png',
})
*/
Vue.prototype.$L = L

declare module 'vue/types/vue' {
  interface Vue {
    $L: typeof L
  }
}
