import { Location } from 'vue-router';

export interface MenuRequestLocation {
  /** メニュー選択画面へのロケーション */
  toMenuRequestMenuSelect: (params: { projectId: string }) => Location;

  /** 同意確認画面へのロケーション */
  toMenuRequestConsentConfirm: (params: { projectId: string }) => Location;

  /** 予約日選択画面へのロケーション */
  toMenuRequestDateSelect: (params: { projectId: string, placeType: string }) => Location;

  /** 身分証登録・確認画面へのロケーション */
  toMenuRequestIdCard: (params: { projectId: string, date: string, time: string }) => Location;

  /** 保険証登録・確認画面へのロケーション */
  toMenuRequestInsuranceCard: (params: { projectId: string }) => Location;

  /** 支払い情報登録・確認画面へのロケーション */
  toMenuRequestPaymentCard: (params: { projectId: string }) => Location;

  /** 問診回答画面へのロケーション */
  toMenuRequestQuestionnaire: (params: { projectId: string }) => Location;

  /** 薬剤受け取り方法登録画面へのロケーション */
  toMenuRequestMedicationPickup: (params: { projectId: string }) => Location;

  /** 予約内容確認画面へのロケーション */
  toMenuRequestQuestionnaireConfirm: (params: { projectId: string }) => Location;

  /** 予約詳細画面へのロケーション */
  toMenuRequestQuestionnaireDetail: (params: { projectId: string }) => Location;
}

export const useMenuRequestLocation = (): MenuRequestLocation => {
  // メニュー選択画面
  const toMenuRequestMenuSelect = (params): Location => {
    return {
      name: 'telemedicine-reserve-custom',
      params,
    };
  };

  // 同意確認画面
  const toMenuRequestConsentConfirm = (params): Location => {
    return {
      name: 'menu-request-consent-confirm',
      params,
    };
  };

  // 予約日選択画面
  const toMenuRequestDateSelect = (params): Location => {
    return {
      name: 'menu-request-reserve-date-select',
      params,
    };
  };

  // 身分証登録・確認画面
  const toMenuRequestIdCard = (params): Location => {
    return {
      name: 'menu-request-reserve-id-card',
      params,
    };
  };

  // 保険証登録・確認画面
  const toMenuRequestInsuranceCard = (params): Location => {
    return {
      name: 'menu-request-reserve-insurance-card',
      params,
    };
  };

  // 支払い情報登録・確認画面
  const toMenuRequestPaymentCard = (params): Location => {
    return {
      name: 'menu-request-reserve-payment-card',
      params,
    };
  };

  // 薬剤受け取り方法登録画面
  const toMenuRequestMedicationPickup = (params): Location => {
    return {
      name: 'menu-request-reserve-medicine-pickup',
      params,
    };
  };

  // 問診回答画面
  const toMenuRequestQuestionnaire = (params): Location => {
    return {
      name: 'menu-request-reserve-questionnaire',
      params,
    };
  };

  // 予約内容確認画面
  const toMenuRequestQuestionnaireConfirm = (params): Location => {
    return {
      name: 'menu-request-reserve-questionnaire-confirm',
      params,
    };
  };

  // 予約内容確認画面
  const toMenuRequestQuestionnaireDetail = (params): Location => {
    return {
      name: 'menu-request-reserve-questionnaire-detail',
      params,
    };
  };

  return {
    toMenuRequestMenuSelect,
    toMenuRequestConsentConfirm,
    toMenuRequestDateSelect,
    toMenuRequestIdCard,
    toMenuRequestInsuranceCard,
    toMenuRequestPaymentCard,
    toMenuRequestMedicationPickup,
    toMenuRequestQuestionnaire,
    toMenuRequestQuestionnaireConfirm,
    toMenuRequestQuestionnaireDetail,
  };
};
