import { Location } from 'vue-router';

export interface LaSuiteLocation {
  /** カード一覧 */
  toCardListLocation: () => Location;
  /** カードタイプ一覧 */
  toExperiencesTypeList: (params) => Location;
  /** ストーリー一覧 */
  toExperiencesStoryList: () => Location;
  /** ストーリー詳細 */
  toExperiencesStoryDetail: (params) => Location;
}

export const useLaSuiteLocation = (): LaSuiteLocation => {
  const toCardListLocation = (): Location => {
    return {
      name: 'experiences',
    };
  };

  const toExperiencesTypeList = (params): Location => {
    return {
      name: 'experiences-list',
      params,
    };
  };

  const toExperiencesStoryList = (): Location => {
    return {
      name: 'experiences-story-list',
    };
  };

  const toExperiencesStoryDetail = (params): Location => {
    return {
      name: 'experiences-story-detail',
      params,
    };
  };

  return {
    toCardListLocation,
    toExperiencesTypeList,
    toExperiencesStoryList,
    toExperiencesStoryDetail,
  };
};
