import { MenuRequestQueryFormData } from '~/types/MenuRequest';

export interface MenuRequestApi {
  postMenuRequest: (requestBody: MenuRequestQueryFormData) => Promise<void>;
}

export const useMenuRequestApi = (fetch): MenuRequestApi => {
  const postMenuRequest = async (requestBody: MenuRequestQueryFormData) => {
    await fetch('/menu-request', requestBody);
  };

  return { postMenuRequest };
};
