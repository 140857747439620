import { Location } from 'vue-router';

export interface MemberListLocation {
  /** F05 [only patient] マイ医療チームへのロケーション */
  toTeamLocation: () => Location;

  /** F05 [only doctor] 患者リストへのロケーション */
  toPatientListLocation: () => Location;

  /** ユーザ診療ステータス一覧へのロケーション */
  toUserTreatmentStatusList: () => Location;

  /** 請求明細一覧一覧へのロケーション */
  toUserRequestDetailList: (params: { projectId: string, patientUserName?: string}) => Location;

  /** ユーザ管理一覧へのロケーション */
  toUserList: () => Location;

  /** 検査機器一覧画面の取得 */
  toInspectionEquipmentList: () => Location;

  /** 検査機器のレポート画面 */
  toInspectionEquipmentReport: (params: { projectId: string}, query:{ medicalDeviceId: string }) => Location;

}

export const useMemberListLocation = (): MemberListLocation => {
  const toTeamLocation = (): Location => {
    return {
      name: 'team-custom',
    };
  };

  const toPatientListLocation = (): Location => {
    return {
      name: 'patient-list-custom',
    };
  };

  const toUserTreatmentStatusList = (): Location => {
    return {
      name: 'user-treatment-status-list',
    };
  };

  const toUserRequestDetailList = (params: { projectId: string, patientUserName?: string }): Location => {
    return {
      name: 'user-request-detail-list',
      params,
    };
  };

  const toUserList = (): Location => {
    return {
      name: 'user-list',
    };
  };

  const toInspectionEquipmentList = (): Location => {
    return {
      name: 'inspection-equipment-list',
    };
  };

  const toInspectionEquipmentReport = (params: { projectId: string}, query:{ medicalDeviceId: string }): Location => {
    return {
      name: 'inspection-equipment-report',
      params,
      query,
    };
  };

  return {
    toTeamLocation,
    toPatientListLocation,
    toUserTreatmentStatusList,
    toUserRequestDetailList,
    toUserList,
    toInspectionEquipmentList,
    toInspectionEquipmentReport,
  };
};
