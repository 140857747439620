import { Pages } from '~/components/settings/Page';

export function pageName(name: string): string {
  const titleMap = {
    // common
    '404-custom': Pages.NOT_FOUND,
    top: Pages.TOP,
    'home-custom': Pages.HOME,
    'profile-custom': Pages.PROFILE,
    'profile-edit-custom': Pages.PROFILE_EDIT,
    'profile-other-custom': Pages.PROFILE_OTHER,
    'profile-login-history': Pages.PROFILE_LOGIN_HISTORY,
    'profile-confirm-custom': Pages.PROFILE_CONFIRM,
    'todos-custom': Pages.TODOS,
    'personal-data-custom': Pages.PERSONALDATA,
    'contents-custom': Pages.CONTENTS,
    'questionnaire-result-custom': Pages.QUESTIONNAIRE_RESULT,
    'chat-custom': Pages.CHAT,
    'hospital-requirement-result-custom': Pages.HOSPITAL_REQUIREMENT_RESULT,
    'hospital-introduction-result-custom': Pages.HOSPITAL_INTRODUCTION_RESULT,
    'hospital-introduction-history-custom': Pages.HOSPITAL_INTRODUCTION_HISTORY,
    'hospital-requirement-charge-result-custom': Pages.HOSPITAL_REQUIREMENT_CHARGE_RESULT,
    'hospital-introduction-charge-result-custom': Pages.HOSPITAL_INTRODUCTION_CHARGE_RESULT,
    'hospital-introduction-charge-history-custom': Pages.HOSPITAL_INTRODUCTION_CHARGE_HISTORY,
    'hospital-introduction-charge-custom': Pages.HOSPITAL_INTRODUCTION_CHARGE,
    'video-meeting-custom': Pages.VIDEO_CHAT,
    'telemedicine-custom': Pages.TELEMEDICINE,
    'telemedicine-charge-custom': Pages.TELEMEDICINE_CHARGE,
    'medical-consulting-custom': Pages.MEDICAL_CONSULTING,
    'medical-consulting-charge-custom': Pages.MEDICAL_CONSULTING_CHARGE,
    'specialist-chat-custom': Pages.SPECIALIST_CHAT,
    'specialist-chat-charge-custom': Pages.SPECIALIST_CHAT_CHARGE,
    'invoice-card-charge-custom': Pages.INVOICE_CHARGE,

    // patient
    'employee-code-input': Pages.EMPLOYEE_CODE_INPUT,
    'welcome-custom': Pages.WELCOME,
    'welcome-interview-custom': Pages.WELCOME_INTERVIEW,
    'team-custom': Pages.TEAM,
    'questionnaire-custom': Pages.QUESTIONNAIRE,
    'questionnaire-confirm-custom': Pages.QUESTIONNAIRE_CONFIRM,
    'hospital-requirement-form-custom': Pages.HOSPITAL_REQUIREMENT_FORM,
    'hospital-requirement-form-confirm-custom':
      Pages.HOSPITAL_REQUIREMENT_FORM_CONFIRM,
    'hospital-requirement-charge-form-custom':
      Pages.HOSPITAL_REQUIREMENT_CHARGE_FORM,
    'hospital-requirement-charge-form-confirm-custom':
      Pages.HOSPITAL_REQUIREMENT_CHARGE_FORM_CONFIRM,
    'hospital-introduction-charge-payment-custom':
      Pages.HOSPITAL_INTRODUCTION_CHARGE_PAYMENT,
    'payment-custom': Pages.PAYMENT,
    'telemedicine-charge-payment-custom': Pages.TELEMEDICINE_CHARGE_PAYMENT,
    'telemedicine-reserve-custom': Pages.TELEMEDICINE_RESERVE,
    'medical-consulting-charge-payment-custom':
      Pages.MEDICAL_CONSULTING_CHARGE_PAYMENT,
    'medical-consulting-reserve-custom': Pages.MEDICAL_CONSULTING_RESERVE,
    'specialist-chat-charge-payment-custom':
      Pages.SPECIALIST_CHAT_CHARGE_PAYMENT,
    'invoice-card-charge-payment-custom': Pages.INVOICE_CHARGE_PAYMENT,
    experiences: Pages.EXPERIENCE_AREA,
    'experiences-list': Pages.EXPERIENCE_TYPE_LIST,
    'experiences-story-list': Pages.EXPERIENCE_STORY_LIST,
    'experiences-story-detail': Pages.EXPERIENCE_STORY_DETAIL,

    // doctor
    'patient-list-custom': Pages.PATIENT_LIST,
    'questionnaire-select-custom': Pages.QUESTIONNAIRE_SELECT,
    'questionnaire-select-confirm-custom': Pages.QUESTIONNAIRE_SELECT_CONFIRM,
    'questionnaire-detail-custom': Pages.QUESTIONNAIRE_DETAIL,
    'video-chat-create-custom': Pages.VIDEO_CHAT_CREATE,
    'hospital-requirement-list-custom': Pages.HOSPITAL_REQUIREMENT_LIST,
    'hospital-requirement-list-confirm-custom':
      Pages.HOSPITAL_REQUIREMENT_LIST_CONFIRM,
    'hospital-requirement-detail-custom': Pages.HOSPITAL_REQUIREMENT_DETAIL,
    'hospital-introduction-form-custom': Pages.HOSPITAL_INTRODUCTION_FORM,
    'hospital-introduction-form-confirm-custom':
      Pages.HOSPITAL_INTRODUCTION_FORM_CONFIRM,
    'hospital-requirement-charge-list-custom':
      Pages.HOSPITAL_REQUIREMENT_CHARGE_LIST,
    'hospital-requirement-charge-list-confirm-custom':
      Pages.HOSPITAL_REQUIREMENT_CHARGE_LIST_CONFIRM,
    'hospital-requirement-charge-detail-custom':
      Pages.HOSPITAL_REQUIREMENT_CHARGE_DETAIL,
    'hospital-introduction-charge-form-custom':
      Pages.HOSPITAL_INTRODUCTION_CHARGE_FORM,
    'hospital-introduction-charge-form-confirm-custom':
      Pages.HOSPITAL_INTRODUCTION_CHARGE_FORM_CONFIRM,
    'hospital-introduction-charge-create-custom':
      Pages.HOSPITAL_INTRODUCTION_CHARGE_CREATE,
    'hospital-introduction-charge-create-confirm-custom':
      Pages.HOSPITAL_INTRODUCTION_CHARGE_CONFIRM,
    'telemedicine-create-custom': Pages.TELEMEDICINE_CREATE,
    'telemedicine-charge-create-custom': Pages.TELEMEDICINE_CHARGE_CREATE,
    'telemedicine-charge-create-confirm-custom':
      Pages.TELEMEDICINE_CHARGE_CONFIRM,
    'medical-consulting-create-custom': Pages.MEDICAL_CONSULTING_CREATE,
    'medical-consulting-charge-create-custom':
      Pages.MEDICAL_CONSULTING_CHARGE_CREATE,
    'medical-consulting-charge-create-confirm-custom':
      Pages.MEDICAL_CONSULTING_CHARGE_CONFIRM,
    'specialist-chat-create-custom': Pages.SPECIALIST_CHAT_CREATE,
    'free-specialist-chat-create-custom': Pages.FREE_SPECIALIST_CHAT_CREATE,
    'paid-specialist-chat-create-custom': Pages.PAID_SPECIALIST_CHAT_CREATE,
    'specialist-chat-charge-create-custom': Pages.SPECIALIST_CHAT_CHARGE_CREATE,
    'specialist-chat-charge-create-confirm-custom':
      Pages.SPECIALIST_CHAT_CHARGE_CONFIRM,
    'consultation-summary-custom': Pages.CONSULTATION_SUMMARY,
    'event-counting-summary-custom': Pages.EVENT_COUNTING_SUMMARY,
    'bookable-times-custom': Pages.BOOKABLE_TIMES,
    'telemedicine-list-custom': Pages.TELEMEDICINE_LIST,
    // コンシェルジュ
    'user-treatment-status-list': Pages.USER_TREATMENT_STATUS_LIST,
    'user-request-detail-list': Pages.USER_REQUEST_DETAIL_LIST,
    'user-list': Pages.USER_LIST,
    'inspection-equipment-list': Pages.INSPECTION_EQUIPMENT_LIST,
    'inspection-equipment-report': Pages.INSPECTION_EQUIPMENT_REPORT,
  };

  return titleMap[name];
}
