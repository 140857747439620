import { ContentDetail, ContentList } from '~/types/Contents';

export interface ContentsApi {
  registerContent: (contentData: ContentDetail) => Promise<{ id: number }>;
  fetchContentsList: (page: number, size?: number) => Promise<ContentList>;
  fetchContentDetail: (contentId: number, requestType: 'card' | 'detail') => Promise<ContentDetail>;
  fetchContentBody: (url: string) => Promise<any>;
}

export const useContentsApi = (fetch): ContentsApi => {
  /** コンテンツの登録 */
  const registerContent = async (contentData: ContentDetail): Promise<{ id: number }> => {
    const response = await fetch('/content/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contentData),
    });
    return response.data;
  };

  /** コンテンツ一覧の取得 */
  const fetchContentsList = async (page: number, size = 10): Promise<ContentList> => {
    const queryParams = new URLSearchParams({ page: page.toString(), size: size.toString() });
    const response = await fetch(`/content/list?${queryParams}`);
    return response.data;
  };

  /** コンテンツ詳細の取得 */
  const fetchContentDetail = async (
    contentId: number,
    requestType: 'card' | 'detail',
  ): Promise<ContentDetail> => {
    // const queryParams = new URLSearchParams({ requestType });
    const response = await fetch(`/content/${contentId}/${requestType}`);

    return response.data;
  };

  const fetchContentBody = async (url: string): Promise<any> => {
    const response = await fetch(url);

    return response.data;
  };

  return {
    registerContent,
    fetchContentsList,
    fetchContentDetail,
    fetchContentBody,
  };
};
