import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fce615da = () => interopDefault(import('../nuxt/pages/~404.vue' /* webpackChunkName: "" */))
const _29ea96b0 = () => interopDefault(import('../nuxt/pages/~top.vue' /* webpackChunkName: "" */))
const _3f815604 = () => interopDefault(import('../nuxt/pages/~login.vue' /* webpackChunkName: "" */))
const _2b6c5750 = () => interopDefault(import('../nuxt/pages/~register.vue' /* webpackChunkName: "" */))
const _6297b60c = () => interopDefault(import('../nuxt/pages/~callback.vue' /* webpackChunkName: "" */))
const _942e4ad2 = () => interopDefault(import('../nuxt/pages/~home/index.vue' /* webpackChunkName: "" */))
const _637b8f07 = () => interopDefault(import('../nuxt/pages/~profile/index.vue' /* webpackChunkName: "" */))
const _221f1765 = () => interopDefault(import('../nuxt/pages/~profile/edit.vue' /* webpackChunkName: "" */))
const _89e70746 = () => interopDefault(import('../nuxt/pages/~profile/loginHistory.patient.vue' /* webpackChunkName: "" */))
const _1fa19645 = () => interopDefault(import('../nuxt/pages/~profile/other.vue' /* webpackChunkName: "" */))
const _763bf2b5 = () => interopDefault(import('../nuxt/pages/~profile/confirm.vue' /* webpackChunkName: "" */))
const _530e2d41 = () => interopDefault(import('../nuxt/pages/~questionnaire/result/index.vue' /* webpackChunkName: "" */))
const _241175d0 = () => interopDefault(import('../nuxt/pages/~chat/index.vue' /* webpackChunkName: "" */))
const _314f98fc = () => interopDefault(import('../nuxt/pages/~hospital/requirement/result/index.vue' /* webpackChunkName: "" */))
const _583cf195 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/result/index.vue' /* webpackChunkName: "" */))
const _72db73b2 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/history/index.vue' /* webpackChunkName: "" */))
const _285f966f = () => interopDefault(import('../nuxt/pages/~hospital/requirement/charge/result/index.vue' /* webpackChunkName: "" */))
const _49d30fb6 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge/result/index.vue' /* webpackChunkName: "" */))
const _97edcc9e = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge/history/index.vue' /* webpackChunkName: "" */))
const _bd798928 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge/index.vue' /* webpackChunkName: "" */))
const _3401e60b = () => interopDefault(import('../nuxt/pages/~todos/index.vue' /* webpackChunkName: "" */))
const _4177bab3 = () => interopDefault(import('../nuxt/pages/~video/video-chat.vue' /* webpackChunkName: "" */))
const _0e9ff81d = () => interopDefault(import('../nuxt/pages/~video/video-chat-leave.vue' /* webpackChunkName: "" */))
const _0c42465c = () => interopDefault(import('../nuxt/pages/~telemedicine/index.vue' /* webpackChunkName: "" */))
const _6c55bec6 = () => interopDefault(import('../nuxt/pages/~telemedicine/charge/index.vue' /* webpackChunkName: "" */))
const _55cb746a = () => interopDefault(import('../nuxt/pages/~medical-consulting/index.vue' /* webpackChunkName: "" */))
const _eee6b262 = () => interopDefault(import('../nuxt/pages/~medical-consulting/charge/index.vue' /* webpackChunkName: "" */))
const _ad0a29dc = () => interopDefault(import('../nuxt/pages/~specialist-chat/index.vue' /* webpackChunkName: "" */))
const _7871e327 = () => interopDefault(import('../nuxt/pages/~specialist-chat/charge/index.vue' /* webpackChunkName: "" */))
const _8cf4175e = () => interopDefault(import('../nuxt/pages/~invoice-card/charge/index.vue' /* webpackChunkName: "" */))
const _46eb3a62 = () => interopDefault(import('../nuxt/pages/~video-chat-help/index.vue' /* webpackChunkName: "" */))
const _07b206dc = () => interopDefault(import('../nuxt/pages/~contents/index.vue' /* webpackChunkName: "" */))
const _210ba541 = () => interopDefault(import('../nuxt/pages/~contents/detail.vue' /* webpackChunkName: "" */))
const _281885d2 = () => interopDefault(import('../nuxt/pages/~welcome/index.patient.vue' /* webpackChunkName: "" */))
const _037e5c46 = () => interopDefault(import('../nuxt/pages/~welcome/interview.patient.vue' /* webpackChunkName: "" */))
const _cdad2340 = () => interopDefault(import('../nuxt/pages/~list/team.patient.vue' /* webpackChunkName: "" */))
const _782852d8 = () => interopDefault(import('../nuxt/pages/~questionnaire/index.patient.vue' /* webpackChunkName: "" */))
const _77834206 = () => interopDefault(import('../nuxt/pages/~questionnaire/confirm.patient.vue' /* webpackChunkName: "" */))
const _d1f3730c = () => interopDefault(import('../nuxt/pages/~hospital/requirement/form/index.patient.vue' /* webpackChunkName: "" */))
const _56b2d528 = () => interopDefault(import('../nuxt/pages/~hospital/requirement/form/confirm.patient.vue' /* webpackChunkName: "" */))
const _12d121ad = () => interopDefault(import('../nuxt/pages/~hospital/requirement/charge/form/index.patient.vue' /* webpackChunkName: "" */))
const _0b33af9b = () => interopDefault(import('../nuxt/pages/~hospital/requirement/charge/form/confirm.patient.vue' /* webpackChunkName: "" */))
const _2bd6e1b7 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge/payment.patient.vue' /* webpackChunkName: "" */))
const _4a051a8a = () => interopDefault(import('../nuxt/pages/~payment/index.patient.vue' /* webpackChunkName: "" */))
const _b25684b0 = () => interopDefault(import('../nuxt/pages/~telemedicine/charge/payment.patient.vue' /* webpackChunkName: "" */))
const _7707ad5a = () => interopDefault(import('../nuxt/pages/~medical-consulting/charge/payment.patient.vue' /* webpackChunkName: "" */))
const _552f5bb2 = () => interopDefault(import('../nuxt/pages/~specialist-chat/charge/payment.patient.vue' /* webpackChunkName: "" */))
const _0bf07a5d = () => interopDefault(import('../nuxt/pages/~telemedicine/reserve.patient.vue' /* webpackChunkName: "" */))
const _4f661e20 = () => interopDefault(import('../nuxt/pages/~menu-request/index.patient.vue' /* webpackChunkName: "" */))
const _f8bb0e2a = () => interopDefault(import('../nuxt/pages/~menu-request/consent-confirm.patient.vue' /* webpackChunkName: "" */))
const _1339a806 = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/date-select.patient.vue' /* webpackChunkName: "" */))
const _99a647e6 = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/id-card.patient.vue' /* webpackChunkName: "" */))
const _3a338014 = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/insurance-card.patient.vue' /* webpackChunkName: "" */))
const _4851ab4a = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/payment-card.patient.vue' /* webpackChunkName: "" */))
const _46aec57a = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/medicine-pickup.patient.vue' /* webpackChunkName: "" */))
const _0bd702ae = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/questionnaire.patient.vue' /* webpackChunkName: "" */))
const _34d18921 = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/questionnaire-confirm.patient.vue' /* webpackChunkName: "" */))
const _b3e7aae4 = () => interopDefault(import('../nuxt/pages/~menu-request/reserve/questionnaire-detail.patient.vue' /* webpackChunkName: "" */))
const _0a4c222a = () => interopDefault(import('../nuxt/pages/~medical-consulting/reserve.patient.vue' /* webpackChunkName: "" */))
const _2ab0ff48 = () => interopDefault(import('../nuxt/pages/~invoice-card/charge/payment.patient.vue' /* webpackChunkName: "" */))
const _668187f1 = () => interopDefault(import('../nuxt/pages/~home/timeline.doctor.vue' /* webpackChunkName: "" */))
const _bd07d7d4 = () => interopDefault(import('../nuxt/pages/~list/patientList.doctor.vue' /* webpackChunkName: "" */))
const _38bcad1d = () => interopDefault(import('../nuxt/pages/~questionnaire/select/index.doctor.vue' /* webpackChunkName: "" */))
const _79d323ef = () => interopDefault(import('../nuxt/pages/~questionnaire/select/confirm.doctor.vue' /* webpackChunkName: "" */))
const _30ad80cb = () => interopDefault(import('../nuxt/pages/~questionnaire/detail.doctor.vue' /* webpackChunkName: "" */))
const _b92f39d0 = () => interopDefault(import('../nuxt/pages/~video/create.doctor.vue' /* webpackChunkName: "" */))
const _b4d056c0 = () => interopDefault(import('../nuxt/pages/~hospital/requirement/list/index.doctor.vue' /* webpackChunkName: "" */))
const _1c8276b2 = () => interopDefault(import('../nuxt/pages/~hospital/requirement/list/confirm.doctor.vue' /* webpackChunkName: "" */))
const _1a998c70 = () => interopDefault(import('../nuxt/pages/~hospital/requirement/detail.doctor.vue' /* webpackChunkName: "" */))
const _2236af7e = () => interopDefault(import('../nuxt/pages/~hospital/introduction/form/index.doctor.vue' /* webpackChunkName: "" */))
const _45e8e613 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/form/confirm.doctor.vue' /* webpackChunkName: "" */))
const _3e3b470d = () => interopDefault(import('../nuxt/pages/~hospital/requirement/charge/list/index.doctor.vue' /* webpackChunkName: "" */))
const _1a1301df = () => interopDefault(import('../nuxt/pages/~hospital/requirement/charge/list/confirm.doctor.vue' /* webpackChunkName: "" */))
const _05893d5d = () => interopDefault(import('../nuxt/pages/~hospital/requirement/charge/detail.doctor.vue' /* webpackChunkName: "" */))
const _167d9280 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge/form/index.doctor.vue' /* webpackChunkName: "" */))
const _46dbd0d2 = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge/form/confirm.doctor.vue' /* webpackChunkName: "" */))
const _f089f96c = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge-create/index.doctor.vue' /* webpackChunkName: "" */))
const _0294a2dc = () => interopDefault(import('../nuxt/pages/~hospital/introduction/charge-create/confirm.doctor.vue' /* webpackChunkName: "" */))
const _57977ebb = () => interopDefault(import('../nuxt/pages/~telemedicine/charge-create/index.doctor.vue' /* webpackChunkName: "" */))
const _4d40060d = () => interopDefault(import('../nuxt/pages/~telemedicine/charge-create/confirm.doctor.vue' /* webpackChunkName: "" */))
const _b6aa134a = () => interopDefault(import('../nuxt/pages/~telemedicine/create.doctor.vue' /* webpackChunkName: "" */))
const _1ef17526 = () => interopDefault(import('../nuxt/pages/~medical-consulting/charge-create/index.doctor.vue' /* webpackChunkName: "" */))
const _6958e83f = () => interopDefault(import('../nuxt/pages/~medical-consulting/charge-create/confirm.doctor.vue' /* webpackChunkName: "" */))
const _8637932e = () => interopDefault(import('../nuxt/pages/~medical-consulting/create.doctor.vue' /* webpackChunkName: "" */))
const _6cb2f5de = () => interopDefault(import('../nuxt/pages/~specialist-chat/create.doctor.vue' /* webpackChunkName: "" */))
const _07fff076 = () => interopDefault(import('../nuxt/pages/~specialist-chat/charge-create/index.doctor.vue' /* webpackChunkName: "" */))
const _79aa7497 = () => interopDefault(import('../nuxt/pages/~specialist-chat/charge-create/confirm.doctor.vue' /* webpackChunkName: "" */))
const _5cfdba24 = () => interopDefault(import('../nuxt/pages/~chat/select.vue' /* webpackChunkName: "" */))
const _4c08085a = () => interopDefault(import('../nuxt/pages/~consultation-summary/index.vue' /* webpackChunkName: "" */))
const _58691397 = () => interopDefault(import('../nuxt/pages/~event-counting-summary/index.vue' /* webpackChunkName: "" */))
const _8612bc78 = () => interopDefault(import('../nuxt/pages/~invoice-card/create/index.doctor.vue' /* webpackChunkName: "" */))
const _513aea54 = () => interopDefault(import('../nuxt/pages/~invoice-card/create/confirm.doctor.vue' /* webpackChunkName: "" */))
const _28c4d809 = () => interopDefault(import('../nuxt/pages/~bookable-times/index.doctor.vue' /* webpackChunkName: "" */))
const _59f901f9 = () => interopDefault(import('../nuxt/pages/~telemedicine/list.doctor.vue' /* webpackChunkName: "" */))
const _df916cf0 = () => interopDefault(import('../nuxt/pages/~list/userTreatmentStatus.concierge.vue' /* webpackChunkName: "" */))
const _5ee23788 = () => interopDefault(import('../nuxt/pages/~list/userRequestDetailList.concierge.vue' /* webpackChunkName: "" */))
const _369ca4fa = () => interopDefault(import('../nuxt/pages/~list/userList.concierge.vue' /* webpackChunkName: "" */))
const _4f2b0983 = () => interopDefault(import('../nuxt/pages/~list/inspectionEquipment.concierge.vue' /* webpackChunkName: "" */))
const _d05d11dc = () => interopDefault(import('../nuxt/pages/~list/inspectionEquipmentReport.vue' /* webpackChunkName: "" */))
const _aeb24656 = () => interopDefault(import('../nuxt/pages/~video-external/index.vue' /* webpackChunkName: "" */))
const _55876f14 = () => interopDefault(import('../nuxt/pages/~video-external/video-external-leave.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "*",
    component: _fce615da,
    name: "404-custom"
  }, {
    path: "/top",
    component: _29ea96b0,
    meta: {"permissions":["guest"]},
    name: "top-custom"
  }, {
    path: "/login",
    component: _3f815604,
    meta: {"permissions":["guest"]},
    name: "login-custom"
  }, {
    path: "/register",
    component: _2b6c5750,
    meta: {"permissions":["guest"]},
    name: "register-custom"
  }, {
    path: "/callback",
    component: _6297b60c,
    meta: {"permissions":["guest"]},
    name: "callback-custom"
  }, {
    path: "/",
    component: _942e4ad2,
    name: "home-custom"
  }, {
    path: "/profile",
    component: _637b8f07,
    name: "profile-custom"
  }, {
    path: "/profile/edit",
    component: _221f1765,
    name: "profile-edit-custom"
  }, {
    path: "/profile/:userId/login-history",
    component: _89e70746,
    name: "profile-login-history"
  }, {
    path: "/profile/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: _1fa19645,
    name: "profile-other-custom"
  }, {
    path: "/profile/confirm",
    component: _763bf2b5,
    name: "profile-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/questionnaire-result/:questionnaireId([0-9]{1,})",
    component: _530e2d41,
    name: "questionnaire-result-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/chat/:chatId",
    component: _241175d0,
    name: "chat-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-result/:hospitalConditionId([0-9]{1,})",
    component: _314f98fc,
    name: "hospital-requirement-result-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-result/:hospitalIntroductionId([0-9]{1,})",
    component: _583cf195,
    name: "hospital-introduction-result-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-history",
    component: _72db73b2,
    name: "hospital-introduction-history-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge-result/:hospitalConditionId([0-9]{1,})",
    component: _285f966f,
    name: "hospital-requirement-charge-result-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-charge-result/:hospitalIntroductionId([0-9]{1,})",
    component: _49d30fb6,
    name: "hospital-introduction-charge-result-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-charge-history",
    component: _97edcc9e,
    name: "hospital-introduction-charge-history-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-charge/:hospitalIntroductionPaymentId([0-9]{1,})",
    component: _bd798928,
    name: "hospital-introduction-charge-custom"
  }, {
    path: "/todos",
    component: _3401e60b,
    name: "todos-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/videoMeetings/:videoMeetingId([0-9]{1,})",
    component: _4177bab3,
    name: "video-meeting-custom"
  }, {
    path: "/video-chat-leave",
    component: _0e9ff81d,
    name: "video-chat-leave-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine/:telemedicineId([0-9]{1,})",
    component: _0c42465c,
    name: "telemedicine-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine-charge/:telemedicineId([0-9]{1,})",
    component: _6c55bec6,
    name: "telemedicine-charge-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting/:medicalConsultingId([0-9]{1,})",
    component: _55cb746a,
    name: "medical-consulting-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting-charge/:medicalConsultingId([0-9]{1,})",
    component: _eee6b262,
    name: "medical-consulting-charge-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/specialist-chat/:specialistChatId([0-9]{1,})",
    component: _ad0a29dc,
    name: "specialist-chat-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/specialist-chat-charge/:specialistChatId([0-9]{1,})",
    component: _7871e327,
    name: "specialist-chat-charge-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/invoice-card-charge/:invoiceId([0-9]{1,})",
    component: _8cf4175e,
    name: "invoice-card-charge-custom"
  }, {
    path: "/video-chat-help",
    component: _46eb3a62,
    name: "video-chat-help-custom"
  }, {
    path: "/contents",
    component: _07b206dc,
    name: "contents-custom"
  }, {
    path: "/contents/:detailId([0-9]{1,})",
    component: _210ba541,
    name: "contents-detail-custom"
  }, {
    path: "/welcome",
    component: _281885d2,
    meta: {"permissions":["patient"]},
    name: "welcome-custom"
  }, {
    path: "/welcome-interview",
    component: _037e5c46,
    meta: {"permissions":["patient"]},
    name: "welcome-interview-custom"
  }, {
    path: "/team",
    component: _cdad2340,
    meta: {"permissions":["patient"]},
    name: "team-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/questionnaire/:questionnaireId([0-9]{1,})",
    component: _782852d8,
    meta: {"permissions":["patient"]},
    name: "questionnaire-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/questionnaire/:questionnaireId([0-9]{1,})/confirm",
    component: _77834206,
    meta: {"permissions":["patient"]},
    name: "questionnaire-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-form/:hospitalConditionId([0-9]{1,})",
    component: _d1f3730c,
    meta: {"permissions":["patient"]},
    name: "hospital-requirement-form-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-form/:hospitalConditionId([0-9]{1,})/confirm",
    component: _56b2d528,
    meta: {"permissions":["patient"]},
    name: "hospital-requirement-form-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge-form/:hospitalConditionId([0-9]{1,})",
    component: _12d121ad,
    meta: {"permissions":["patient"]},
    name: "hospital-requirement-charge-form-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge-form/:hospitalConditionId([0-9]{1,})/confirm",
    component: _0b33af9b,
    meta: {"permissions":["patient"]},
    name: "hospital-requirement-charge-form-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge/:hospitalIntroductionPaymentId([0-9]{1,})/payment",
    component: _2bd6e1b7,
    meta: {"permissions":["patient"]},
    name: "hospital-introduction-charge-payment-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/payment/:paymentId([0-9]{1,})",
    component: _4a051a8a,
    name: "payment-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine-charge/:telemedicineId([0-9]{1,})/payment",
    component: _b25684b0,
    meta: {"permissions":["patient"]},
    name: "telemedicine-charge-payment-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting-charge/:medicalConsultingId([0-9]{1,})/payment",
    component: _7707ad5a,
    meta: {"permissions":["patient"]},
    name: "medical-consulting-charge-payment-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/specialist-chat-charge/:specialistChatId([0-9]{1,})/payment",
    component: _552f5bb2,
    meta: {"permissions":["patient"]},
    name: "specialist-chat-charge-payment-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine-reserve/:questionnaireId([0-9]{1,})",
    component: _0bf07a5d,
    meta: {"permissions":["patient"]},
    name: "telemedicine-reserve-custom"
  }, {
    path: "/menu-request",
    component: _4f661e20,
    meta: {"permissions":["patient"]},
    name: "menu-request-menu-select"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/consent-confirm",
    component: _f8bb0e2a,
    meta: {"permissions":["patient"]},
    name: "menu-request-consent-confirm"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/date-select",
    component: _1339a806,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-date-select"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/id-card",
    component: _99a647e6,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-id-card"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/insurance-card",
    component: _3a338014,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-insurance-card"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/payment-card",
    component: _4851ab4a,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-payment-card"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/medicine-pickup",
    component: _46aec57a,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-medicine-pickup"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/questionnaire",
    component: _0bd702ae,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-questionnaire"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/questionnaire-confirm",
    component: _34d18921,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-questionnaire-confirm"
  }, {
    path: "/projects/:projectId([0-9]{1,})/menu-request/reserve/questionnaire-detail",
    component: _b3e7aae4,
    meta: {"permissions":["patient"]},
    name: "menu-request-reserve-questionnaire-detail"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting-reserve/:questionnaireId([0-9]{1,})",
    component: _0a4c222a,
    meta: {"permissions":["patient"]},
    name: "medical-consulting-reserve-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/invoice-card-charge/:invoiceId([0-9]{1,})/payment",
    component: _2ab0ff48,
    meta: {"permissions":["patient"]},
    name: "invoice-card-charge-payment-custom"
  }, {
    path: "/home/:projectId([0-9]{1,})",
    component: _668187f1,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "home-timeline-custom"
  }, {
    path: "/patient-list",
    component: _bd07d7d4,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "patient-list-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/questionnaire-select",
    component: _38bcad1d,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "questionnaire-select-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/questionnaire-select/:templateId([0-9]{1,})/confirm",
    component: _79d323ef,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "questionnaire-select-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/questionnaire-detail/:questionnaireId([0-9]{1,})",
    component: _30ad80cb,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "questionnaire-detail-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/video-chat-create",
    component: _b92f39d0,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "video-chat-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-list",
    component: _b4d056c0,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-requirement-list-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-list/:templateId([0-9]{1,})/confirm",
    component: _1c8276b2,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-requirement-list-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-detail/:hospitalConditionId([0-9]{1,})",
    component: _1a998c70,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-requirement-detail-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-form/:hospitalConditionId([0-9]{1,})",
    component: _2236af7e,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-introduction-form-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-form/:hospitalConditionId([0-9]{1,})/confirm",
    component: _45e8e613,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-introduction-form-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge-list",
    component: _3e3b470d,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-requirement-charge-list-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge-list/:templateId([0-9]{1,})/confirm",
    component: _1a1301df,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-requirement-charge-list-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-requirement-charge-detail/:hospitalConditionId([0-9]{1,})",
    component: _05893d5d,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-requirement-charge-detail-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-charge-form/:hospitalConditionId([0-9]{1,})",
    component: _167d9280,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-introduction-charge-form-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-form/:hospitalConditionId([0-9]{1,})/confirm",
    component: _46dbd0d2,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "hospital-introduction-charge-form-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-charge-create/:hospitalIntroductionId([0-9]{1,})",
    component: _f089f96c,
    name: "hospital-introduction-charge-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/hospital-introduction-charge-create-confirm/:hospitalIntroductionId([0-9]{1,})",
    component: _0294a2dc,
    name: "hospital-introduction-charge-create-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine-charge-create/:telemedicineId([0-9]{1,})",
    component: _57977ebb,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "telemedicine-charge-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine-charge-create/:telemedicineId([0-9]{1,})/confirm",
    component: _4d40060d,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "telemedicine-charge-create-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/telemedicine-create",
    component: _b6aa134a,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "telemedicine-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting-charge-create/:medicalConsultingId([0-9]{1,})",
    component: _1ef17526,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "medical-consulting-charge-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting-charge-create/:medicalConsultingId([0-9]{1,})/confirm",
    component: _6958e83f,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "medical-consulting-charge-create-confirm-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/medical-consulting-create",
    component: _8637932e,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "medical-consulting-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/free-specialist-chat-create",
    component: _6cb2f5de,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "free-specialist-chat-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/paid-specialist-chat-create",
    component: _6cb2f5de,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "paid-specialist-chat-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/specialist-chat-charge-create/:specialistChatId([0-9]{1,})",
    component: _07fff076,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "specialist-chat-charge-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/specialist-chat-charge-create/:specialistChatId([0-9]{1,})/confirm",
    component: _79aa7497,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "specialist-chat-charge-create-confirm-custom"
  }, {
    path: "/chat-select/:projectId([0-9]{1,})",
    component: _5cfdba24,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "chat-select"
  }, {
    path: "/consultation-summary",
    component: _4c08085a,
    name: "consultation-summary-custom"
  }, {
    path: "/event-counting-summary",
    component: _58691397,
    name: "event-counting-summary-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/invoice-card-create",
    component: _8612bc78,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "invoice-card-create-custom"
  }, {
    path: "/projects/:projectId([0-9]{1,})/invoice-card-create/confirm",
    component: _513aea54,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "invoice-card-create-confirm-custom"
  }, {
    path: "/bookable-times",
    component: _28c4d809,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "bookable-times-custom"
  }, {
    path: "/telemedicine-list",
    component: _59f901f9,
    meta: {"permissions":["doctor","nurse","registered_dietitian","clinical_psychologist","concierge"]},
    name: "telemedicine-list-custom"
  }, {
    path: "/user-treatment-status-list",
    component: _df916cf0,
    meta: {"permissions":"concierge"},
    name: "user-treatment-status-list"
  }, {
    path: "/user-request-detail-list",
    component: _5ee23788,
    meta: {"permissions":"concierge"},
    name: "user-request-detail-list"
  }, {
    path: "/user-list",
    component: _369ca4fa,
    meta: {"permissions":"concierge"},
    name: "user-list"
  }, {
    path: "/inspection-equipment-list",
    component: _4f2b0983,
    meta: {"permissions":"concierge"},
    name: "inspection-equipment-list"
  }, {
    path: "/inspection-equipment-report/:projectId([0-9]{1,})",
    component: _d05d11dc,
    meta: {"permissions":["concierge","doctor","nurse","patient"]},
    name: "inspection-equipment-report"
  }, {
    path: "/external/projects/:projectId([0-9]{1,})/telemedicine/:telemedicineId([0-9]{1,})",
    component: _aeb24656,
    name: "external-video-external"
  }, {
    path: "/external/projects/:projectId([0-9]{1,})/telemedicine/:telemedicineId([0-9]{1,})/leave",
    component: _55876f14,
    name: "external-video-external-leave"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
