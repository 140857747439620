import {
  ActivationStatus, Activity, PersonalData, PersonalDataRequest,
} from '~/types/PersonalData';

export interface PersonalDataApi {
  /** PHRデータの取得 */
  getPersonalData: () => Promise<PersonalData>

  /** PHRデータの更新（patch） */
  updatePersonalData: (requestBody: PersonalDataRequest) => Promise<PersonalData>

  /** mywellness連携 */
  linkMywellness: () => Promise<'already_registered' | 'registered'>

  /** 連携状況の取得 */
  getActivationStatus: () => Promise<ActivationStatus>

  /** 連携解除 */
  unlinkMywellness: () => Promise<void>

  /** トレーニングデータの取得 */
  fetchTrainingData: () => Promise<Activity[]>
}

export const usePersonalDataApi = (fetch, axios): PersonalDataApi => {
  const getPersonalData = async (): Promise<PersonalData> => {
    const res = await fetch('/phr-data');
    return res.data.phrData;
  };

  const updatePersonalData = async (requestBody: PersonalDataRequest): Promise<PersonalData> => {
    const res = await axios.patch('/phr-data', requestBody);
    return res.data.phrData;
  };

  const getActivationStatus = async (): Promise<ActivationStatus> => {
    const res = await axios.get('/mywellness/status');
    return res.data;
  };

  const linkMywellness = async (): Promise<'already_registered' | 'registered'> => {
    try {
      const res = await axios.post('/mywellness/register', {});
      return res.data.status;
    } catch (error) {
      console.error('mywellness連携に失敗:', error);
      throw error;
    }
  };

  const fetchTrainingData = async (): Promise<Activity[]> => {
    const res = await axios.get('/mywellness/getActivities');
    return res.data.activities;
  };

  const unlinkMywellness = async (): Promise<void> => {
    await axios.delete('/mywellness/deactivate');
  };

  return {
    getPersonalData,
    updatePersonalData,
    getActivationStatus,
    linkMywellness,
    fetchTrainingData,
    unlinkMywellness,
  };
};
