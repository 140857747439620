import { Context } from '@nuxt/types';
import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';

import { Location } from 'vue-router';
import { Project, MedicalTeam, ProjectMenu } from '~/types/Project';
import { TreatmentStatus } from '~/types/TreatmentStatus';
import { Member } from '~/types/User';

export interface ProjectState {
  projects: Ref<Project[] | null>;
  firstProject: Ref<Project | null>;
  firstProjectTeam: Ref<MedicalTeam | null>;
  projectLimit: Ref<number>;
  menuData: Ref<Record<number, ProjectMenu>>;
  treatmentStatusList: TreatmentStatus[] | null;
  timeLineReturnLink: Ref<Location | string>;
  initProject: () => Promise<void>,
  getPatientProjectIdByUuid: (uuid: string) => string | null,
  getProjectMenu: (projectId: string) => Promise<void>,
  getProjectByProjectId: (projectId: string) => Project | null,
  getPatientByProjectId: (projectId: string) => Member | null,
  getTeamByProjectId: (projectId: string) => Promise<MedicalTeam | null>,
  updateProjects: (data: Project[]) => Promise<void>,
  addProjectByProjectId: (projectId: string) => Promise<void>,
  fetchFirstTeamByProjectId: () => Promise<void>,
}

export const useProjectState = (context: Context): ProjectState => {
  /** project リスト */
  const projects = ref<Project[] | null>(null);

  /** 最初に所属した（0番目の）project */
  const firstProject = ref<Project | null>(null);

  /** 最初に所属した（0番目の）project のチーム情報 */
  const firstProjectTeam = ref<MedicalTeam | null>(null);

  /** プロジェクト取得件数 */
  const projectLimit = ref<number>(100);

  /** 「+」ボタンの表示分け判定データ */
  const menuData = ref<Record<number, ProjectMenu>>({});

  /** 診療ステータス */
  const treatmentStatusList: TreatmentStatus[] = [];

  /** timeLine画面の戻るボタンの遷移先 */
  const timeLineReturnLink = ref<Location | string>('');

  /** 患者のUUIDからプロジェクトIDを取得する */
  const getPatientProjectIdByUuid = (uuid: string): string | null => {
    if (!projects.value) return null;
    const project = projects.value.find(({ patient }) => patient?.uuid === uuid);
    return project?.id || null;
  };

  /** プロジェクトIDからプロジェクト情報を取得する */
  const getProjectByProjectId = (projectId: string): Project | null => {
    if (!projects.value) return null;
    return projects.value.find(({ id }) => id === projectId) || null;
  };

  /** プロジェクトIDから患者情報を取得する */
  const getPatientByProjectId = (projectId: string): Member | null => {
    const project = getProjectByProjectId(projectId);
    return project?.patient || null;
  };

  /** プロジェクトIDから取得したチーム情報を返却する */
  const getTeamByProjectId = async (projectId: string): Promise<MedicalTeam | null> => {
    const targetProject = getProjectByProjectId(projectId);
    const medicalTeamId = targetProject?.medicalTeams[0].id;
    if (medicalTeamId) {
      const team = await context.$api.getMedicalTeamsByProjectIdAndMedicalTeamId(projectId, medicalTeamId);
      return team;
    }

    return null;
  };

  /** プロジェクト追加読み込み */
  const updateProjects = async (data: Project[]): Promise<void> => {
    const ids = data.map(({ id }) => id);

    projects.value = [
      // 重複分は削除
      ...(projects.value || []).filter(({ id }) => !ids.includes(id)),
      ...data,
    ];
  };

  /** タイムラインや各ページで、未読み込みのプロジェクトがあったら取得し、プロジェクトリストに追加する */
  const addProjectByProjectId = async (projectId: string): Promise<void> => {
    // 未保存の project のみ fetch する
    if (!getProjectByProjectId(projectId)) {
      const project = await context.$api.getProjetById(projectId);

      // 念の為二重でガード
      if (!getProjectByProjectId(projectId) && projects.value) {
        projects.value.unshift(project);
      }
    }
  };

  /** プロジェクトIDから最初に所属した（0番目の）project のチーム情報 */
  const fetchFirstTeamByProjectId = async (): Promise<void> => {
    if (firstProject.value) {
      const medicalTeam = firstProject.value?.medicalTeams[0];
      const targetFirstProjectTeam = await context.$api.getMedicalTeamsByProjectIdAndMedicalTeamId(firstProject.value.id, medicalTeam.id);
      firstProjectTeam.value = targetFirstProjectTeam;
    }
  };

  /** 「+」ボタンの表示分け判定データ取得 */
  const getProjectMenu = async (projectId: string): Promise<void> => {
    if (!menuData.value[projectId]) {
      menuData.value[projectId] = await context.$api.getProjectMenuByProjectId(projectId);
    }
  };

  const initProject = async (): Promise<void> => {
    const [project, treatmentStatus] = await context.$api.getProjets({ limit: projectLimit.value });
    projects.value = project;

    // treatmentStatusListに診療ステータスリスト追加
    treatmentStatusList.length = 0;

    // 初回ログインではundefinedになるため
    if (treatmentStatus) {
      treatmentStatusList.push(...treatmentStatus);
    }

    const [firstProjectData] = project;

    // TODO: 検証段階では $s.firstProject が無いことがあるのでとりあえずのエラー回避
    if (firstProjectData) {
      firstProject.value = getProjectByProjectId(firstProjectData.id);
    }
  };

  return {
    projects,
    firstProject,
    firstProjectTeam,
    projectLimit,
    menuData,
    treatmentStatusList,
    timeLineReturnLink,
    initProject,
    getProjectMenu,
    getPatientProjectIdByUuid,
    getProjectByProjectId,
    getPatientByProjectId,
    getTeamByProjectId,
    updateProjects,
    addProjectByProjectId,
    fetchFirstTeamByProjectId,
  };
};
