import { ExperiencesResponseData, StoryResponseData } from '~/types/Experiences';

export interface ExperiencesApi {
  /** カード一覧取得 */
  fetchExperiencesList: () => Promise<ExperiencesResponseData[]>;
  /** カード詳細取得 */
  getDetailByExperienecId: (id: number) => Promise<ExperiencesResponseData>;
  /** ストーリー一覧取得 */
  fetchStoryList: () => Promise<StoryResponseData[]>;
  /** ストーリーおすすめ一覧取得 */
  fetchRecommendStoryList: () => Promise<StoryResponseData[]>;
  /** Favoriteの追加 */
  addFavorite: (storyId: string) => Promise<void>;
  /** Favoriteの削除 */
  removeFavorite: (storyId: string) => Promise<void>;
  /** ストーリー詳細取得 */
  getStoryDetailById:(storyId: string) => Promise<StoryResponseData>;
  /** カード詳細表示記録 */
  postAnalyticsForDetailView: (id: number, url: string) => Promise<void>
  /** カードリンククリック記録 */
  postAnalyticsForExternalClick: (id: number, url: string) => Promise<void>
  /** エリアごとのカード一覧取得 */
  fetchExperiencesListByAreaId: (areaId: number) => Promise<ExperiencesResponseData[]>
}

export const useExperiencesApi = (fetch, axios): ExperiencesApi => {
  /** カード一覧取得 */
  const fetchExperiencesList = async (): Promise<ExperiencesResponseData[]> => {
    try {
      const { data, status } = await fetch('/experiences/list');
      if (status === 200) {
        return data.experiences;
      }
      throw new Error('カード一覧「experiences」取得失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`カード一覧「experiences」取得失敗: ${e}`);
    }
  };

  /** カード詳細取得 */
  const getDetailByExperienecId = async (id: number): Promise<ExperiencesResponseData> => {
    try {
      const { data, status } = await fetch(`/experiences/detail/${id}`);
      if (status === 200) {
        return data;
      }
      throw new Error('カード詳細「experiences」取得失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`カード詳細「experiences」取得失敗: ${e}`);
    }
  };

  /** ストーリー一覧取得 */
  const fetchStoryList = async (): Promise<StoryResponseData[]> => {
    try {
      const { data, status } = await fetch('/stories/list');
      if (status === 200) {
        return data.stories;
      }
      throw new Error('ストーリー一覧「stories」取得失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`ストーリー一覧「stories」取得失敗: ${e}`);
    }
  };

  const fetchRecommendStoryList = async (): Promise<StoryResponseData[]> => {
    try {
      const { data, status } = await fetch('/stories/recommendationList');
      if (status === 200) {
        return data.stories;
      }
      throw new Error('ストーリーおすすめ一覧「stories」取得失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`ストーリーおすすめ一覧「stories」取得失敗: ${e}`);
    }
  };

  const addFavorite = async (storyId: string): Promise<void> => {
    try {
      const { status } = await axios.post(`/stories/favorite/${storyId}`);
      if (status !== 201) {
        throw new Error('Add Favorite 失敗, statusは201ではない');
      }
    } catch (e) {
      throw new Error(`Add Favorite 失敗: ${e}`);
    }
  };

  const removeFavorite = async (storyId: string): Promise<void> => {
    try {
      const { status } = await axios.delete(`/stories/favorite/${storyId}`);
      if (status !== 200) {
        throw new Error('Remove Favorite 失敗, statusは201ではない');
      }
    } catch (e) {
      throw new Error(`Remove Favorite 失敗: ${e}`);
    }
  };

  const getStoryDetailById = async (storyId: string): Promise<StoryResponseData> => {
    try {
      const { status, data } = await fetch(`/stories/${storyId}`);
      if (status === 200) {
        return data;
      }
      throw new Error('ストーリー詳細取得失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`ストーリー詳細取得失敗: ${e}`);
    }
  };

  /** カード詳細表示記録 */
  const postAnalyticsForDetailView = async (experienceId: number, url: string): Promise<void> => {
    try {
      const { data, status } = await fetch(`/analytics/experiences/${experienceId}/detail_view`, {
        url,
      });
      if (status === 201) {
        return data;
      }
      throw new Error('カード詳細表示記録失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`カード詳細表示記録失敗: ${e}`);
    }
  };

  /** カードリンククリック記録 */
  const postAnalyticsForExternalClick = async (experienceId: number, url: string): Promise<void> => {
    try {
      const { data, status } = await fetch(`/analytics/experiences/${experienceId}/external_click`, {
        url,
      });
      if (status === 201) {
        return data;
      }
      throw new Error('カードリンククリック録失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`カードリンククリック記録失敗: ${e}`);
    }
  };

  /** エリアごとのカード一覧取得 */
  const fetchExperiencesListByAreaId = async (areaId: number): Promise<ExperiencesResponseData[]> => {
    try {
      const { data, status } = await fetch(`/experiences/list?experienceArea=${areaId}`);
      if (status === 200) {
        return data.experiences;
      }
      throw new Error('エリアごとのカード一覧取得失敗, statusは200ではない');
    } catch (e) {
      throw new Error(`エリアごとのカード一覧取得失敗: ${e}`);
    }
  };
  return {
    fetchStoryList,
    fetchRecommendStoryList,
    fetchExperiencesList,
    getDetailByExperienecId,
    addFavorite,
    removeFavorite,
    getStoryDetailById,
    postAnalyticsForDetailView,
    postAnalyticsForExternalClick,
    fetchExperiencesListByAreaId,
  };
};
