import { Location } from 'vue-router';

export interface BookableTimesLocation {
  toBookableTimes: () => Location;
}

export const useBookableTimesLocation = (): BookableTimesLocation => {
  const toBookableTimes = (): Location => {
    return {
      name: 'bookable-times-custom',
    };
  };

  return {
    toBookableTimes,
  };
};
