import { Location } from 'vue-router';

export interface WelcomeLocation {
  /** 従業員コード入力画面 */
  toEmployeeCodeInput: () => Location;
  /** F01 初回ログインへのロケーション */
  toWelcomeLocation: () => Location;

  /** F01 初回アンケートへのロケーション */
  toWelcomeInterviewLocation: () => Location;
}

export const useWelcomeLocation = (): WelcomeLocation => {
  const toEmployeeCodeInput = (): Location => {
    return {
      name: 'employee-code-input',
    };
  };
  const toWelcomeLocation = (): Location => {
    return {
      name: 'welcome-custom',
    };
  };

  const toWelcomeInterviewLocation = (): Location => {
    return {
      name: 'welcome-interview-custom',
    };
  };

  return {
    toEmployeeCodeInput,
    toWelcomeLocation,
    toWelcomeInterviewLocation,
  };
};
