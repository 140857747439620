/* eslint-disable no-shadow */
export enum CardType {
  /** 病院紹介希望条件カード */
  HospitalCondition = 'hospitalCondition',

  /** 病院紹介カード */
  HospitalIntroduction = 'hospitalIntroduction',

  /** 病院紹介（有料）カード */
  HospitalIntroductionsCharged = 'hospitalIntroductionsCharged',

  /** 問診カード */
  Questionnaire = 'inquiry',

  /** 通知カード */
  Notification = 'notification',

  /** チャットカード */
  Chat = 'chat',

  /** オンライン医療相談 */
  VideoMeeting = 'videoMeeting',

  /** オンライン医療相談（有料） */
  MedicalConsultationCharged = 'medicalConsultationCharged',

  /** チャット医療相談 */
  SpecialistChat = 'specialistChat',

  /** welcome カード */
  Welcome = 'welcome',

  /** welcome（問診あり） カード */
  WelcomeQuestionnaire = 'welcomeInquiry',

  /** オンライン診療カード */
  Telemedicine = 'telemedicine',

  /** 請求カード */
  Invoice = 'payment',

  /** 診療レポート */
  MedicalDevice = 'medical-device',

  /** コンテンツカード */
  Content = 'content',
}

export enum CardEventType {
  /**
   * [問診、病院紹介希望条件] 回答前
   * [請求] 医療者が請求済みの状態
  */
  Received = 'received',

  /** 回答後 */
  Answered = 'answered',

  /** 結果 */
  Results = 'results',

  /** 初回ログインカード 問診あり 医療者確認済 */
  Read = 'read',

  /** 削除済み */
  Deleted = 'deleted',

  /** 削除済み */
  Chat = 'chat',

  /** [オンライン医療相談、診療] 予約済み */
  Reserved = 'reserved',

  /** [オンライン医療相談、診療] 一時間前イベント */
  OneHourAgo = 'oneHourAgo',

  /** [オンライン医療相談、診療] 一日前イベント */
  OneDayAgo = 'oneDayAgo',

  /** [オンライン診療] オンライン診療中の状態 */
  Started = 'started',

  /** [オンライン診療] オンライン診療が終了し、未請求の状態 */
  Ended = 'ended',

  /** [オンライン診療] オンライン診療が終了し、請求済みの状態 */
  PaymentReceived = 'paymentReceived',

  /** [オンライン診療] オンライン診療が終了し、患者が決済完了の状態 */
  PaymentCompleted = 'paymentCompleted',

  /** [請求] 決済完了 */
  Completed = 'completed',
}

export enum StorageName {
  Timeline = 'timeline',

  UserTimeline = 'u_timeline',

  Toast = 'toast_id',
}

export enum ModuleName {
  VideoMeetings = 'videoMeetings',

  HospitalConditions = 'hospitalConditions',

  HospitalIntroductions = 'hospitalIntroductions',

  InquiryResults = 'inquiryResults',

  Enquetes = 'enquetes',

  MedicalDiagnosis = 'medicalDiagnosis',

  MedicalConsultationCharged = 'medicalConsultationCharged',

  SpecialistChat = 'specialistChat',

  HospitalIntroductionsCharged = 'hospitalIntroductionsCharged',

  Invoice = 'invoice',

  InHouseChat = 'inHouseChat',

  PatientChat = 'patientChat',
}
/* eslint-enable */
