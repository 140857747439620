import { VideoMeeting } from '~/types/VideoMeeting';
import { InvoiceRequestQueryFormData } from '~/types/Invoice';
import { CreateCardRequestQuery } from '~/types/Timeline';
import { TelemedicineInvoice, TelemedicineDetail, TelemedicineList } from '~/types/Telemedicine';

export interface TelemedicineApi {
  /** オンライン診療の作成 */
  createTelemedicine: (projectId: string, requestBody: CreateCardRequestQuery) => Promise<void>

  /** メニューリクエストからオンライン診療の作成 */
  createTelemedicineFromMenuRequest: (projectId: string, requestBody: CreateCardRequestQuery) => Promise<any>

  /** オンライン診療情報の取得 */
  getTelemedicine: (projectId: string, telemedicineId: string) => Promise<VideoMeeting | null>

  /** オンライン診療詳細情報取得 */
  getTelemedicineDetail: (projectId: string, telemedicineId: string) => Promise<TelemedicineDetail | null>

  /** オンライン診療の入室時情報更新（入退室のログ等を記録） */
  postTelemedicine: (projectId: string, telemedicineId: string) => Promise<void>

  /** オンライン診療の終了 */
  finishTelemedicine: (projectId: string, telemedicineId: string) => Promise<void>

  /** オンライン診療の削除 */
  deleteTelemedicine: (projectId: string, telemedicineId: string) => Promise<void>

  /** 診療明細情報取得 */
  getTelemedicineInvoice: (projectId: string, telemedicineId: string) => Promise<TelemedicineInvoice | null>

  /** オンライン診療明細の送信 */
  postTelemedicineInvoice: (projectId: string, telemedicineId: string, requestBody: InvoiceRequestQueryFormData) => Promise<void>

  /** 支払いの送信 */
  postTelemedicineInvoicePayment: (projectId: string, telemedicineId: string) => Promise<void>

  /** オンライン診療のQRコード取得 */
  getTelemedicineQRCode: (projectId: string, telemedicineId: string) => Promise<string>

  /** オンライン診療一覧取得 */
  getTelemedicineList: () => Promise<TelemedicineList[]>
}

export const useTelemedicineApi = (fetch, axios): TelemedicineApi => {
  const createTelemedicine = async (projectId: string, requestBody): Promise<void> => {
    await fetch(`/projects/${projectId}/medicalDiagnoses`, requestBody);
  };

  const createTelemedicineFromMenuRequest = async (projectId: string, requestBody): Promise<any> => {
    const response = await fetch(`/projects/${projectId}/medicalDiagnoses/menu-request`, requestBody);
    return response.data;
  };

  const getTelemedicine = async (projectId: string, telemedicineId: string): Promise<VideoMeeting | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('オンライン診療情報が取得できませんでした');
      }
      return null;
    }
  };

  const getTelemedicineDetail = async (projectId: string, telemedicineId: string): Promise<TelemedicineDetail | null> => {
    try {
      const { data } = await fetch(`projects/${projectId}/medicalDiagnoses/${telemedicineId}/medicalDiagnosisInfo`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('オンライン診療詳細情報が取得できませんでした');
      }
      return null;
    }
  };

  const postTelemedicine = async (projectId: string, telemedicineId: string): Promise<void> => {
    await axios.post(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}`);
  };

  const finishTelemedicine = async (projectId: string, telemedicineId: string): Promise<void> => {
    await axios.put(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}`);
  };

  const deleteTelemedicine = async (projectId: string, telemedicineId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}`);
  };

  const getTelemedicineInvoice = async (projectId: string, telemedicineId: string): Promise<TelemedicineInvoice | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}/payments`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('診療明細情報が取得できませんでした');
      }
      return null;
    }
  };

  const postTelemedicineInvoice = async (projectId: string, telemedicineId: string, requestBody: InvoiceRequestQueryFormData): Promise<void> => {
    await fetch(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}/payments`, requestBody);
  };

  const postTelemedicineInvoicePayment = async (projectId: string, telemedicineId: string): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}/paymentSubmit`, {});
    if (response.data.status === 'failed') throw new Error('カード情報の取得に失敗しました。');
  };

  const getTelemedicineQRCode = async (projectId: string, telemedicineId: string): Promise<string> => {
    const { data } = await fetch(`/projects/${projectId}/medicalDiagnoses/${telemedicineId}/qr-code`);
    return data;
  };

  const getTelemedicineList = async (): Promise<TelemedicineList[]> => {
    const { data } = await fetch(`/menu-request/medical-diagnosis/list`);
    return data;
  };

  return {
    createTelemedicine,
    createTelemedicineFromMenuRequest,
    getTelemedicine,
    postTelemedicine,
    finishTelemedicine,
    deleteTelemedicine,
    postTelemedicineInvoice,
    getTelemedicineDetail,
    getTelemedicineInvoice,
    postTelemedicineInvoicePayment,
    getTelemedicineQRCode,
    getTelemedicineList,
  };
};
