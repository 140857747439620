import { Location } from 'vue-router';

export interface SpecialistChatLocation {
  /** P-80 チャット医療相談(無料) の作成へのロケーション */
  toFreeSpecialistChatCreateLocation: (params: {
    projectId: string;
  }) => Location;

  /** P-80 チャット医療相談(有料) の作成へのロケーション */
  toPaidSpecialistChatCreateLocation: (params: {
    projectId: string;
  }) => Location;

  /** P-81 チャット医療相談へのロケーション */
  toSpecialistChatLocation: (params: {
    projectId: string;
    specialistChatId: string;
  }) => Location;

  /** P-82 チャット医療相談 請求 作成 */
  toSpecialistChatChargeCreateLocation: (params: {
    projectId: string;
    specialistChatId: string;
  }) => Location;

  /** P-83 チャット医療相談 請求 確認 */
  toSpecialistChatChargeConfirmLocation: (params: {
    projectId: string;
    specialistChatId: string;
  }) => Location;

  /** P-84-1, P-84-2, P-84-4 チャット医療相談 請求の詳細 */
  toSpecialistChatChargeLocation: (params: {
    projectId: string;
    specialistChatId: string;
  }) => Location;

  /** P-84-3 チャット医療相談 請求詳細（患者側決済前）へのロケーション */
  toSpecialistChatChargePaymentLocation: (params: {
    projectId: string;
    specialistChatId: string;
  }) => Location;
}

export const useSpecialistChatLocation = (): SpecialistChatLocation => {
  const toFreeSpecialistChatCreateLocation = (params): Location => {
    return {
      name: 'free-specialist-chat-create-custom',
      params,
    };
  };

  const toPaidSpecialistChatCreateLocation = (params): Location => {
    return {
      name: 'paid-specialist-chat-create-custom',
      params,
    };
  };

  const toSpecialistChatLocation = (params): Location => {
    return {
      name: 'specialist-chat-custom',
      params,
    };
  };

  const toSpecialistChatChargeCreateLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-create-custom',
      params,
    };
  };

  const toSpecialistChatChargeConfirmLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-create-confirm-custom',
      params,
    };
  };

  const toSpecialistChatChargeLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-custom',
      params,
    };
  };

  const toSpecialistChatChargePaymentLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-payment-custom',
      params,
    };
  };

  return {
    toSpecialistChatChargeCreateLocation,
    toSpecialistChatLocation,
    toSpecialistChatChargeConfirmLocation,
    toFreeSpecialistChatCreateLocation,
    toPaidSpecialistChatCreateLocation,
    toSpecialistChatChargeLocation,
    toSpecialistChatChargePaymentLocation,
  };
};
