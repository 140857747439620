import { render, staticRenderFns } from "./default.doctor.vue?vue&type=template&id=07521ae6&"
import script from "./default.doctor.vue?vue&type=script&lang=ts&"
export * from "./default.doctor.vue?vue&type=script&lang=ts&"
import style0 from "./default.doctor.vue?vue&type=style&index=0&lang=postcss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VNavigationDrawer})
