/* eslint_disable no_shadow */
export enum Pages {
  // common
  NOT_FOUND = '404 Not found',
  TOP = 'TOP',
  HOME = 'タイムライン',
  PROFILE = 'プロフィール',
  PROFILE_LOGIN_HISTORY = 'ログイン履歴',
  PROFILE_OTHER = 'プロフィール',
  PROFILE_EDIT = 'プロフィール編集',
  PROFILE_CONFIRM = '送信完了',
  TODOS = 'やることリスト',
  PERSONALDATA = 'マイデータ',
  CONTENTS = '健康マガジン',
  QUESTIONNAIRE_RESULT = '問診回答の結果',
  CHAT = 'チャット',
  HOSPITAL_REQUIREMENT_RESULT = '病院紹介希望条件の回答結果',
  HOSPITAL_INTRODUCTION_RESULT = '紹介結果',
  HOSPITAL_INTRODUCTION_HISTORY = '紹介履歴',
  HOSPITAL_REQUIREMENT_CHARGE_RESULT = '病院紹介希望条件の回答結果',
  HOSPITAL_INTRODUCTION_CHARGE_RESULT = '紹介結果',
  HOSPITAL_INTRODUCTION_CHARGE_HISTORY = '紹介履歴',
  HOSPITAL_INTRODUCTION_CHARGE = '請求の詳細',
  VIDEO_CHAT = 'オンライン医療相談',
  TELEMEDICINE = 'オンライン診療',
  TELEMEDICINE_RESERVE = 'オンライン診療の予約',
  TELEMEDICINE_CHARGE = '請求の詳細',
  MEDICAL_CONSULTING = 'オンライン医療相談',
  MEDICAL_CONSULTING_CHARGE = '請求の詳細',
  MEDICAL_CONSULTING_RESERVE = 'オンライン医療相談の予約',
  SPECIALIST_CHAT = '専門医チャット',
  SPECIALIST_CHAT_CHARGE = '専門医チャットの詳細',
  INVOICE_CHARGE = '請求の詳細',

  // patient
  EMPLOYEE_CODE_INPUT = '従業員コードの入力',
  WELCOME = 'マイ医療チームの紹介',
  WELCOME_INTERVIEW = '初回ヒアリング',
  TEAM = 'マイ医療チーム',
  QUESTIONNAIRE = '問診回答の入力',
  QUESTIONNAIRE_CONFIRM = '問診入力内容の確認',
  HOSPITAL_REQUIREMENT_FORM = '病院紹介希望条件の入力',
  HOSPITAL_REQUIREMENT_FORM_CONFIRM = '病院紹介希望条件入力内容のの確認',
  HOSPITAL_REQUIREMENT_CHARGE_FORM = '病院紹介希望条件の入力',
  HOSPITAL_REQUIREMENT_CHARGE_FORM_CONFIRM = '病院紹介希望条件入力内容のの確認',
  HOSPITAL_INTRODUCTION_CHARGE_PAYMENT = '請求の詳細',
  PAYMENT = 'お支払い情報の編集',
  TELEMEDICINE_CHARGE_PAYMENT = '請求の詳細',
  MEDICAL_CONSULTING_CHARGE_PAYMENT = '請求の詳細',
  SPECIALIST_CHAT_CHARGE_PAYMENT = '請求の詳細',
  INVOICE_CHARGE_PAYMENT = '請求の詳細',
  EXPERIENCE_AREA = '体験を見つける',
  EXPERIENCE_TYPE_LIST = '施設',
  EXPERIENCE_STORY_LIST = 'ストーリー',
  EXPERIENCE_STORY_DETAIL = 'ストーリー詳細',

  // doctor
  PATIENT_LIST = '患者リスト',
  USER_TREATMENT_STATUS_LIST = 'ステータス一覧',
  USER_REQUEST_DETAIL_LIST = '請求明細一覧',
  USER_LIST = 'ユーザー管理',
  INSPECTION_EQUIPMENT_LIST = '検査機器一覧',
  INSPECTION_EQUIPMENT_REPORT = '診療レポート',
  QUESTIONNAIRE_SELECT = '問診の送信',
  QUESTIONNAIRE_SELECT_CONFIRM = '問診リストの確認',
  QUESTIONNAIRE_DETAIL = '問診の確認',
  VIDEO_CHAT_CREATE = 'オンライン医療相談の予定を作成',
  HOSPITAL_REQUIREMENT_LIST = '病院紹介希望条件の選択リスト',
  HOSPITAL_REQUIREMENT_LIST_CONFIRM = '病院紹介希望条件の選択リスト確認',
  HOSPITAL_REQUIREMENT_DETAIL = '病院紹介希望条件の確認',
  HOSPITAL_INTRODUCTION_FORM = '病院紹介の入力',
  HOSPITAL_INTRODUCTION_FORM_CONFIRM = '病院紹介の確認',
  HOSPITAL_REQUIREMENT_CHARGE_LIST = '病院紹介希望条件の選択リスト',
  HOSPITAL_REQUIREMENT_CHARGE_LIST_CONFIRM = '病院紹介希望条件の選択リスト確認',
  HOSPITAL_REQUIREMENT_CHARGE_DETAIL = '病院紹介希望条件の確認',
  HOSPITAL_INTRODUCTION_CHARGE_FORM = '病院紹介の入力',
  HOSPITAL_INTRODUCTION_CHARGE_FORM_CONFIRM = '病院紹介の確認',
  HOSPITAL_INTRODUCTION_CHARGE_CREATE = '請求の作成',
  HOSPITAL_INTRODUCTION_CHARGE_CONFIRM = '入力内容の確認',
  TELEMEDICINE_CREATE = 'オンライン診療の作成',
  TELEMEDICINE_CHARGE_CREATE = '請求の作成',
  TELEMEDICINE_CHARGE_CONFIRM = '入力内容の確認',
  MEDICAL_CONSULTING_CREATE = 'オンライン医療相談の作成',
  MEDICAL_CONSULTING_CHARGE_CREATE = '請求の作成',
  MEDICAL_CONSULTING_CHARGE_CONFIRM = '入力内容の確認',
  SPECIALIST_CHAT_CREATE = '専門医チャットの作成',
  FREE_SPECIALIST_CHAT_CREATE = 'チャット医療相談(無料)の作成',
  PAID_SPECIALIST_CHAT_CREATE = 'チャット医療相談(有料)の作成',
  SPECIALIST_CHAT_CHARGE_CREATE = '請求の作成',
  SPECIALIST_CHAT_CHARGE_CONFIRM = '入力内容の確認',
  CONSULTATION_SUMMARY = '相談件数',
  EVENT_COUNTING_SUMMARY = '対応件数',
  BOOKABLE_TIMES = '予約枠',
  TELEMEDICINE_LIST = 'オンライン診療一覧',
}
