import { ref, Ref } from '@nuxtjs/composition-api';
import { ExperiencesResponseData, StoryCardDetail, StoryResponseData } from '~/types/Experiences';

export interface ExperiencesState {
  // 1: StoryStepDetail 2:ExpericeceDetail 3:ExperienceAreaDetail
  situation:Ref<1 | 2 | 3 | null>

  stepDetailData: Ref<StoryCardDetail[]>
  stepsIndex: Ref<number>

  experiencesList: Ref<ExperiencesResponseData[]>
  uniqueExperiencesList: Ref<ExperiencesResponseData[]>
  getExpericecesCardListByTypeId: (typeId: number) => ExperiencesResponseData[]
  initExperiences:(api) => Promise<void>
  experiencesData:Ref<ExperiencesResponseData>

  storyList: Ref<StoryResponseData[]>
  storyThemeIdList: Ref<StoryResponseData[]>
  recommendList: Ref<StoryResponseData[]>
  initStory: (api) => Promise<void>
  storyCardAreaSetting: Ref<any>

  experienceAreaData: Ref<any>
}

export const useExperiencesState = (): ExperiencesState => {
  const situation = ref<1 | 2 | 3 | null>(null);

  const stepDetailData = ref<StoryCardDetail[]>([]);
  const stepsIndex = ref<number>(0);

  const experiencesData = ref<ExperiencesResponseData>({} as any);
  const experiencesList = ref<ExperiencesResponseData[]>([]);
  const uniqueExperiencesList = ref<ExperiencesResponseData[]>([]);

  const storyList = ref<StoryResponseData[]>([]);
  const recommendList = ref<StoryResponseData[]>([]);
  const storyThemeIdList = ref<StoryResponseData[]>([]);

  const experienceAreaData = ref<any>({});

  const storyCardAreaSetting = ref({
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    arrows: false,
    variableWidth: true,
    centerPadding: '0px',
    slidesToScroll: 1,
    dots: true,
  });
  const initExperiences = async (api: any): Promise<void> => {
    if (experiencesList.value.length === 0) {
      const result: ExperiencesResponseData[] = await api.fetchExperiencesList();
      experiencesList.value = result;
      const uniqueTypeIdArr = result.reduce((acc: ExperiencesResponseData[], cur) => {
        if (!acc.some((item) => item.type.id === cur.type.id)) {
          acc.push(cur);
        }
        return acc;
      }, []).sort((a, b) => a.type.id - b.type.id);
      uniqueExperiencesList.value = uniqueTypeIdArr;
    }
  };

  const initStory = async (api: any): Promise<void> => {
    const createUniqueStoryThemeIds = (storyList) => {
      if (storyList.length > 0) {
        const uniqueStoryThemeIdArr = storyList.reduce((acc: StoryResponseData[], cur) => {
          if (!acc.some((item) => item.storyTheme?.id === cur.storyTheme.id)) {
            acc.push(cur);
          }
          return acc;
        }, []).sort((a, b) => a.storyTheme.displayOrder - b.storyTheme.displayOrder);
        return uniqueStoryThemeIdArr;
      }
      return [];
    };

    const apiRes = await Promise.allSettled([
      api.fetchStoryList(),
      api.fetchRecommendStoryList(),
    ]);
    const results = apiRes.map((result, index) => {
      if (result.status === 'fulfilled') {
        return result.value;
      }
      console.log(`ストーリー一覧画面api reject index:${index} `);
      return [];
    });
    storyList.value = results[0];
    recommendList.value = results[1];
    storyThemeIdList.value = createUniqueStoryThemeIds(results[0]);
  };

  const getExpericecesCardListByTypeId = ((typeId: number): ExperiencesResponseData[] => {
    if (experiencesList.value.length === 0) {
      return [];
    }
    return experiencesList.value
      .filter((item: ExperiencesResponseData) => item.type.id === typeId)
      .sort((a, b) => a.type.id - b.type.id);
  });

  return {
    situation,
    stepDetailData,
    stepsIndex,
    experiencesData,
    uniqueExperiencesList,
    storyList,
    recommendList,
    initExperiences,
    initStory,
    storyThemeIdList,
    experiencesList,
    getExpericecesCardListByTypeId,
    storyCardAreaSetting,
    experienceAreaData,
  };
};
