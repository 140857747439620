import { EmployeeCodeResponse, LoginHistory, Me } from '~/types/User';
import { CardInfo } from '~/types/Payment';

export interface ProfileApi {
  getProfile: () => Promise<Me>;
  postProfile: (body) => Promise<any>;
  postEmployee: (body) => Promise<EmployeeCodeResponse>;
  postMailAddress: (body) => Promise<any>;
  postPassword: (body) => Promise<any>;
  setCardInfo: (token: string) => Promise<void>;
  getCardInfo: () => Promise<CardInfo>;
  getLoginHistory: () => Promise<LoginHistory[]>;
}

export const useProfileApi = (fetch): ProfileApi => {
  /**
   * プロフィール取得
   */
  const getProfile = async (): Promise<Me> => {
    const response = await fetch('/me');
    return response.data;
  };

  /**
   * 従業員コードの入力によるユーザー登録
   * @param body {employeeCode}
   */
  const postEmployee = async (body): Promise<EmployeeCodeResponse> => {
    const response = await fetch('/me/registration/employee', body);
    return response.data;
  };

  /**
   * プロフィール変更
   */
  const postProfile = async (body): Promise<any> => {
    const response = await fetch('/me', body);
    return response.data;
  };

  /**
   * メールアドレス変更
   */
  const postMailAddress = async (body): Promise<any> => {
    const response = await fetch('/me/mailaddress', body);
    return response.data;
  };

  /**
   * パスワード変更
   */
  const postPassword = async (body): Promise<any> => {
    const response = await fetch('/me/password', body);
    return response.data;
  };

  /**
   * クレジットカード情報の登録
   */
  const setCardInfo = async (token: string): Promise<void> => {
    try {
      const response = await fetch('/paymentRegister', { token });
      if (response.data.status === 'failed') {
        throw new Error('通信に失敗しました');
      }
    } catch (e) {
      alert('通信に失敗しました');
      throw new Error('通信に失敗しました');
    }
  };

  /**
   * クレジットカード情報の取得
   */
  const getCardInfo = async (): Promise<CardInfo> => {
    try {
      const response = await fetch('/registeredPayments');
      if (response.data.status === 'failed') {
        alert(response.data.message);
        throw new Error('通信に失敗しました');
      }
      return response.data.data[0] as CardInfo;
    } catch (e) {
      throw new Error('通信に失敗しました');
    }
  };

  const getLoginHistory = async (): Promise<LoginHistory[]> => {
    const response = await fetch('/me/login_history');
    return response.data;
  };

  return {
    getProfile,
    postProfile,
    postEmployee,
    postMailAddress,
    postPassword,
    setCardInfo,
    getCardInfo,
    getLoginHistory,
  };
};
