import { ExperienceAreaResponse } from '~/types/FloorMap';

export interface FloorMapApi {
  /** フロアマップの取得 */
  getAreasExperiences: () => Promise<ExperienceAreaResponse[]>
}

export const useFloorMapApi = (fetch): FloorMapApi => {
  const getAreasExperiences = async (): Promise<ExperienceAreaResponse[]> => {
    const response = await fetch('/experiences/area-experiences');
    return response.data.areasExperiences;
  };

  return { getAreasExperiences };
};
