import {
  Menu, TentativeBookingRequestQueryFormData, TimeSlotResponse, UserBookableTimesResponse,
} from '~/types/Menu';

export interface MenuApi {
  /** メニュー情報の取得 */
  getMenuCategories: (projectId: string) => Promise<Menu[]>;

  getMenu: (menuId: number) => Promise<Menu | null>

  getMenuTimeSlots: (menuId: string, equipmentIds: string[]) => Promise<TimeSlotResponse>;

  /** メニュー仮予約 */
  postTentativeBooking: (menuId: string, requestBody: TentativeBookingRequestQueryFormData) => Promise<void>;

  /** メニュー予約確定 */
  postBooking: (menuId: string, requestBody: TentativeBookingRequestQueryFormData) => Promise<void>;

  /** 予約枠の解放 */
  releaseTentativeBooking: (menuId: string, requestBody: TentativeBookingRequestQueryFormData) => Promise<void>;

  /** 予約枠情報の取得 */
  getUserBookableTimes: (userId?: string) => Promise<UserBookableTimesResponse>;

  /** 予約枠情報の更新 */
  updateUserBookableTime: (ubtId: number, requestBody: any) => Promise<boolean>;
}

export const userMenuApi = (fetch, axios): MenuApi => {
  /** メニュー情報の取得 */
  const getMenuCategories = async (projectId: string): Promise<Menu[]> => {
    try {
      const { data } = await fetch(`/menu/project/${projectId}`);
      return data.menuCategories;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'メニュー情報が取得できませんでした');
    }
  };

  const getMenu = async (menuId: number): Promise<Menu | null> => {
    const response = await fetch(`/menu/${menuId}`);
    return response.data.menu;
  };

  const getMenuTimeSlots = async (menuId: string, equipmentIds: string[]): Promise<TimeSlotResponse> => {
    try {
      const { data } = await fetch(`/menu/${menuId}/bookable-time`, { equipmentIds });
      return data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || '予約枠情報が取得できませんでした');
    }
  };

  const postTentativeBooking = async (menuId: string, requestBody: TentativeBookingRequestQueryFormData): Promise<void> => {
    try {
      await fetch(`/menu/${menuId}/tentative-booking`, requestBody);
    } catch (error: any) {
      throw new Error(error.response?.data?.message || '仮予約に失敗しました');
    }
  };

  const postBooking = async (menuId: string, requestBody: TentativeBookingRequestQueryFormData): Promise<void> => {
    try {
      await fetch(`/menu/${menuId}/booking`, requestBody);
    } catch (error: any) {
      throw new Error(error.response?.data?.message || '予約確定に失敗しました');
    }
  };

  const releaseTentativeBooking = async (menuId: string, requestBody: TentativeBookingRequestQueryFormData): Promise<void> => {
    try {
      await fetch(`/menu/${menuId}/release-tentative-booking`, requestBody);
    } catch (error: any) {
      throw new Error(error.response?.data?.message || '予約枠の解放に失敗しました');
    }
  };

  const getUserBookableTimes = async (date?: string): Promise<UserBookableTimesResponse> => {
    const queryString = date ? `date=${date}` : '';
    const response = await fetch(`/menu/user-bookable-times${queryString ? `?${queryString}` : ''}`);
    return response.data;
  };

  const updateUserBookableTime = async (ubtId: number, requestBody: any): Promise<boolean> => {
    const response = await axios.put(`/menu/user-bookable-times/${ubtId}`, requestBody);
    return response.data;
  };

  return {
    getMenuCategories,
    getMenu,
    getMenuTimeSlots,
    postTentativeBooking,
    postBooking,
    releaseTentativeBooking,
    getUserBookableTimes,
    updateUserBookableTime,
  };
};
