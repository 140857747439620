import { stringifyQuery } from '~/components/tool/Query';
import {
  GetTreatmentStatusList, GetUserList, Receipt, UpdateInspectionEquipmentBody, UserList,
} from '~/types/List';
import { Project, ProjectMenu, AssignableUser } from '~/types/Project';
import {
  ApiDataResponse,
  CPAP, FME, InspectionEquipmentResponse, PSG, ProjectByTreatmentStatusesResponse, RAPIDEXAM, TreatmentCategoryType, TreatmentStatus, Vendor,
} from '~/types/TreatmentStatus';

type Query = {
  limit?: number;
  page?: number;
  sort?: 'ASC' | 'DESC';
}

type PatientUserStatusRequestBody = {
  userId: string;
  treatmentCategory: TreatmentCategoryType ;
  treatmentStatus: FME | RAPIDEXAM | PSG | CPAP;
  color: string;
}

export interface ProjectApi {
  /** プロジェクト一覧の取得 */
  getProjets: (query?: Query) => Promise<[Project[], TreatmentStatus[]]>,

  /** プロジェクトIDから特定のプロジェクトを取得 */
  getProjetById: (projectId: string) => Promise<Project>,

  /** 医療側、「+」メニューの表示切り替え判定用データを取得 */
  getProjectMenuByProjectId: (projectId: string) => Promise<ProjectMenu>,

  /** アサイン対象者を取得 */
  getProjectAssignableUsersByProjectId: (projectId: string) => Promise<AssignableUser[]>,

  /** 診療ステータス情報の更新 */
  updateTreatmentStatus: (data: PatientUserStatusRequestBody) => Promise<boolean>,

  /** 診療ステータス管理の一覧取得 */
  getTreatmentStatusList: (query?: GetTreatmentStatusList) => Promise<ApiDataResponse<ProjectByTreatmentStatusesResponse>>,

  /** 登録済ユーザ請求詳細リストの取得 */
  getUserRequestDetailList: (query?: GetUserList) => Promise<ApiDataResponse<UserList>>,

  /** 仮登録ユーザ請求詳細リストの取得 */
  getPreRegisteredUserData: (query?: GetUserList) => Promise<ApiDataResponse<UserList>>,

  /** 領収書一覧画面の取得 */
  getReciptList: (projectId: string) => Promise<Receipt[]>,

  /** 検査機器一覧画面の取得 */
  getInspectionEquipmentList: (query?: Query) => Promise<ApiDataResponse<InspectionEquipmentResponse>>

  /** 検査機器のupdate操作 */
  updateInspectionEquipment: (projectId: string, body:UpdateInspectionEquipmentBody) => Promise<void>,

  /** 検査機器レポートupload操作 */
  uploadMedicalDeviceReport: (projectId: string, medicalDeviceId: string, formData: any) => Promise<void>,

  /** 検査機器レポートget操作 */
  getMedicalDeviceReport: (projectId: string, medicalDeviceId: string,) => Promise<{ url: string, purpose: string}>,

  /** 検査機器レポートdelete操作 */
  deleteMedicalDeviceReport: (projectId: string, medicalDeviceId: string) => Promise<void>,

  /** ベンターリストの取得 */
  getVendorList:() => Promise<Vendor[]>,
}

export const useProjectApi = (fetch, axios): ProjectApi => {
  const getProjets = async (query?: Query): Promise<[Project[], TreatmentStatus[]]> => {
    const response = await fetch(`/projects${stringifyQuery(query)}`);
    return [response.data.data, response.data.treatmentStatusList];
  };

  const getProjetById = async (projectId: string): Promise<Project> => {
    const response = await fetch(`/projects/${projectId}`);
    return response.data;
  };

  const getProjectMenuByProjectId = async (projectId: string): Promise<ProjectMenu> => {
    const response = await fetch(`/projects/${projectId}/timelineMenu`);
    return response.data;
  };

  const getProjectAssignableUsersByProjectId = async (projectId: string): Promise<AssignableUser[]> => {
    const response = await fetch(`/projects/${projectId}/getAssignableUsers`);
    return response.data;
  };

  const updateTreatmentStatus = async (body: PatientUserStatusRequestBody): Promise<boolean> => {
    const response = await fetch('/projects/patient-user-status', body);
    return response.data;
  };

  const getTreatmentStatusList = async (query?: GetTreatmentStatusList): Promise<ApiDataResponse<ProjectByTreatmentStatusesResponse>> => {
    const response = await fetch(`/projects/by-treatment-statuses${stringifyQuery(query)}`);
    return response.data;
  };

  const getUserRequestDetailList = async (query?: GetUserList): Promise<ApiDataResponse<UserList>> => {
    const response = await fetch(`/projects/user-list${stringifyQuery(query)}`);
    return response.data;
  };

  const getPreRegisteredUserData = async (query?: GetUserList): Promise<ApiDataResponse<UserList>> => {
    const response = await fetch(`/projects/pre-registered-user-list${stringifyQuery(query)}`);
    return response.data;
  };

  const getReciptList = async (projectId: string): Promise<Receipt[]> => {
    const response = await fetch(`/projects/${projectId}/receipts`);
    return response.data.data;
  };

  const getInspectionEquipmentList = async (query?: Query): Promise<ApiDataResponse<InspectionEquipmentResponse>> => {
    const response = await fetch(`/projects/with-medical-devices-and-treatment-statuses${stringifyQuery(query)}`);
    return response.data;
  };

  const updateInspectionEquipment = async (projectId: string, body:UpdateInspectionEquipmentBody): Promise<void> => {
    await axios.put(`/medical-device/${projectId}`, body);
  };

  const uploadMedicalDeviceReport = async (projectId:string, medicalDeviceId: string, formData: FormData): Promise<void> => {
    await fetch(`/medical-device/${projectId}/report/${medicalDeviceId}`, formData);
  };

  const getMedicalDeviceReport = async (projectId: string, medicalDeviceId: string): Promise<{ url: string, purpose: string}> => {
    const response = await fetch(`/medical-device/${projectId}/report/${medicalDeviceId}`);
    return response.data;
  };

  const deleteMedicalDeviceReport = async (projectId: string, medicalDeviceId: string): Promise<void> => {
    await axios.delete(`/medical-device/${projectId}/report/${medicalDeviceId}`);
  };

  const getVendorList = async (): Promise<Vendor[]> => {
    const response = await fetch('/medical-device-vendor/list');
    return response.data.data;
  };

  return {
    getProjets,
    getProjetById,
    getProjectMenuByProjectId,
    getProjectAssignableUsersByProjectId,
    updateTreatmentStatus,
    getTreatmentStatusList,
    getUserRequestDetailList,
    getPreRegisteredUserData,
    getReciptList,
    uploadMedicalDeviceReport,
    getMedicalDeviceReport,
    getInspectionEquipmentList,
    updateInspectionEquipment,
    deleteMedicalDeviceReport,
    getVendorList,
  };
};
