import { Location } from 'vue-router';

export interface ContentsLocation {
  toContentsLocation: () => Location;
  toContentsDetailLocation: () => Location;
}

export const useContentsLocation = (): ContentsLocation => {
  const toContentsLocation = (): Location => {
    return {
      name: 'contents-custom',
    };
  };

  const toContentsDetailLocation = (): Location => {
    return {
      name: 'contents-detail-custom',
    };
  };

  return {
    toContentsLocation,
    toContentsDetailLocation,
  };
};
